import axios from '@/libs/axios'

class DataService {
  getAll(page) {
    return axios.get("/providers?page="+page);
  }
  get(id) {
    return axios.get(`/providers/${id}`);
  }
  create(data) {
    return axios.post("/providers", data);
  }
  update(id, data) {
    // return axios.put(`/providers/${id}`, data);
    return axios.put(`/providers`, data);
  }
  delete(id) {
    return axios.delete(`/providers/${id}`);
  }


  getUserCount() {
    return axios.get("/users/count?accountType=1");
  }
  
  getReviewsCount() {
    return axios.get("/reviews/count?provider_id=1");
  }

  reviewData(providerId,page) {
    return axios.get(`/reviews?providerId=${providerId}&page=${page}`);
  }

  deleteReview(id) {
    return axios.delete(`/reviews?id=${id}`);
  }
  
}
export default new DataService();