<template>
    <b-card title="ملف مزود الخدمة">
        <table class="mt-2 mt-xl-0 w-100">

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">اﻹسم</span>
            </th>
            <td class="pb-50">
              {{ providerData.name }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الصورة</span>
            </th>
            <td class="pb-50">
              <span class="b-avatar-img">
                <p v-if="imageUrl">Uploaded Image URL: <a :href="imageUrl" target="_blank">{{ imageUrl }}</a></p>
                <img :src="imageUrl"  alt="" width="100px" height="150px">
              </span>
            </td>
          </tr>
         
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">التقييم</span>
            </th>
            <td class="pb-50">
              {{ providerData.rating }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">عدد التقييمات</span>
            </th>
            <td class="pb-50">
              {{ providerData.reviewsCount }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">المشاريع المكتملة</span>
            </th>
            <td class="pb-50">
              {{ providerData.completedProjects }}
            </td>
          </tr>
 
          
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الوصف</span>
            </th>
            <td>
              {{ providerData.description }}
            </td>
          </tr>

           <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">المراجعات</span>
            </th>
            <td>
              
              <b-link :to="{ name: 'review' }">
                <feather-icon icon="FolderIcon" />
                <span class="align-middle ml-50">المراجعات</span>
              </b-link>

            </td>
          </tr> 
          
        </table>
    </b-card>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BBadge, BLink } from 'bootstrap-vue'

export default {
    components: {
      BLink,
      BRow, 
      BCol,
      BCard,
      BCardText,
      BBadge
    },
    props : {
        providerData : {
            type : Object,
            requied: true
        }
    },
    data() {
        return {
          imageUrl: this.providerData.image,
          status: [
              {
              1: 'Actvie', 0: 'Inactive',
              },
              {
              1: 'light-success', 0: 'light-danger', 
              }
          ],
        }
    },
    mounted() {
      // this.imageUrl = this.providerData.image
    },

}
</script>