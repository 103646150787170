<template>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >


    <b-row>
      <b-col
          cols="12"
          lg="12"
        >
          <!-- <provider-info :providerData="providerData" /> -->

          <b-card title="ملف مزود الخدمة">
        <table class="mt-2 mt-xl-0 w-100">

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">اﻹسم</span>
            </th>
            <td class="pb-50">
              {{ providerData.name }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الصورة</span>
            </th>
            <td class="pb-50">
              <span class="b-avatar-img">
                <!-- <p v-if="imageUrl">Uploaded Image URL: <a :href="imageUrl" target="_blank">{{ imageUrl }}</a></p> -->
                <img :src="imageUrl"  alt="" width="100px" height="150px">
              </span>
            </td>
          </tr>
         
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">التقييم</span>
            </th>
            <td class="pb-50">
              {{ providerData.rating }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">عدد التقييمات</span>
            </th>
            <td class="pb-50">
              {{ providerData.reviewsCount }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">المشاريع المكتملة</span>
            </th>
            <td class="pb-50">
              {{ providerData.completedProjects }}
            </td>
          </tr>
 
          
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الوصف</span>
            </th>
            <td>
              {{ providerData.description }}
            </td>
          </tr>

           <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">المراجعات</span>
            </th>
            <td>
              
              <b-link :to="{ name: 'review' }">
                <feather-icon icon="FolderIcon" />
                <span class="align-middle ml-50">المراجعات</span>
              </b-link>

            </td>
          </tr> 
          
        </table>
    </b-card>
        </b-col>

      
    </b-row>



</b-overlay>

</template>

<script>
import {
  BLink,BCard, BButton, BAvatar, BRow, BCol, BBadge, BOverlay
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DataService  from './data/services'

import providerInfo from './components/providerInfo.vue'
export default {
  components: {
    BLink,BCard, BButton, BRow, BCol, BAvatar, BBadge, BOverlay,
    ToastificationContent,
    providerInfo,
  },

  data() {
    return {
      providerData: {},
      isLoading: true,
      isDataLoaded: false,
      imageUrl:"",
      status: [
        {
          1: 'Actvie', 0: 'Inactive',
        },
        {
          1: 'light-success', 0: 'light-danger', 
        }
      ],
    }
  },
  methods: {
      getData(){
            this.isLoading = true;
            var arr = [];

            DataService.get(this.$route.params.id)
            .then(response => {

                  this.isDataLoaded = true; // Set flag to true once data is loaded

                  this.isLoading = false
                  this.providerData    = response.data
                  this.imageUrl  = response.data.image,
                  
                  this.$toast({
                        component: ToastificationContent,
                        props: {
                              title: 'successfully',
                              icon: 'BellIcon',
                              text: response.data.responseDescription,
                              variant: 'success',
                        },
                  })
            }).catch((error) => {
              console.error("Error fetching data:", error);
              this.isDataLoaded = false; // Handle error
            });
      },
     
      
  },
  mounted() {
    this.getData()
  },
  
}
</script>

<style scoped>
/* Force LTR direction for this container */
.ltr-container {
  direction: ltr;
  text-align: left;
}
</style>
